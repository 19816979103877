import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {
    AppBar,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    createStyles,
    GridList,
    GridListTile,
    Hidden,
    makeStyles,
    Tab,
    Tabs,
    Theme,
    Toolbar,
} from '@material-ui/core';

import { Routes, Route } from 'react-router-dom';
import AccountDeletion from './AccountDeletion';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // backgroundColor: theme.palette.background.default,
            // color: theme.palette.primary.main
        },
        app_bar: {
            backgroundColor: '#FFFFFFA0', // 60% opacity
        },
        container_bg: {
            // backgroundImage is defined in the <div> element style field as a hook.
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            paddingTop: '12vh',
            // backgroundColor:'red',
            minHeight: '80vh',
            maxHeight: '90vh',
        },
        container_bottom_section: {
            paddingBottom: 80,
            // backgroundColor: '#FFFFFFA0'
        },
        copyright_container_bg: {
            // backgroundColor: '#f8f9fa',
            color: '#5f6368', // Text color.
            // padding: 24,
            height: '10vh',
        },
        logo_top: {
            height: '4vh',
            width: '4vh',
            marginTop: 8,
            marginBottom: 8,
            objectFit: 'contain',
        },
        title_top: {
            marginLeft: 16,
            lineHeight: 2,
            flexGrow: 1,
        },
        center_highlight_text: {
            textAlign: 'center',
            fontWeight: 'bold',
        },
        center_highlight_text_new: {
            textAlign: 'center',
        },
        leading_text: {
            // marginBotton: 8,
            paddingBottom: 16,
            textAlign: 'center',
            fontWeight: 'bold',
        },
        tab_general: {
            textTransform: 'none',
        },
        search_bar: {
            padding: '2px 4px 2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: 16,
        },
        search_bar_input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        search_button: {
            padding: 10,
        },
        app_dl_card: {
            width: '100%',
        },
        app_dl_icon: {
            height: 64,
            width: 64,
        },
        app_dl_container: {
            textAlign: 'center',
        },
        app_dl_store_badge: {
            height: 48,
            objectFit: 'contain',
        },
        app_dl_store_badge_same_width: {
            width: 132,
            objectFit: 'contain',
        },
        copyright_item: {
            paddingLeft: 16,
            paddingRight: 16,
        },
    })
);

function Copyright(styles: any) {
    return [
        <Box mb={2} key="cr0">
            <Typography variant="body2" color="textSecondary" align="center">
                <Link
                    color="inherit"
                    href="https://bidwork.com.au/privacy-policy/"
                    className={styles.copyright_item}
                    target="_blank"
                >
                    Privacy Policy
                </Link>
                |
                <Link color="inherit" href="mailto:bidworkteam@gmail.com" className={styles.copyright_item}>
                    Contact Us
                </Link>
            </Typography>
        </Box>,
        <Typography variant="body2" color="textSecondary" align="center" key="cr1">
            {'Copyright © '}
            <Link color="inherit" href="https://www.southfusion.com.au/" target="_blank">
                <b>Southfusion Pty Ltd</b>
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>,
    ];
}

function createAppBar(styles: any, anchorEl: any, setAnchorEl: any) {
    return (
        <AppBar position="fixed" elevation={2} className={styles.app_bar}>
            <Toolbar>
                <img src={process.env.PUBLIC_URL + '/icons/logo_512.png'} alt="Logo" className={styles.logo_top} />
                <Typography variant="h6" className={styles.title_top} noWrap={true}>
                    BidWork
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function AppTiles(styles: any, key: string, badge_class: string, app_store_link: string, play_store_link: string) {
    return [
        <GridListTile key={key + '-apple'} cols={1} className={styles.app_dl_container}>
            <Link href={app_store_link} target="_blank">
                <img
                    src={process.env.PUBLIC_URL + '/icons/app-store-badge.svg'}
                    alt="App store"
                    className={badge_class}
                />
            </Link>
        </GridListTile>,
        <GridListTile key={key + '-google'} cols={1} className={styles.app_dl_container}>
            <Link href={play_store_link} target="_blank">
                <img
                    src={process.env.PUBLIC_URL + '/icons/google-play-badge.png'}
                    alt="Google Play store"
                    className={badge_class}
                />
            </Link>
        </GridListTile>,
    ];
}

function AppCard(
    styles: any,
    key: string,
    title: string,
    subtitle: string,
    isBusiness: boolean,
    app_store_link: string,
    play_store_link: string
) {
    return (
        <Card className={styles.app_dl_card} elevation={4}>
            <CardHeader
                avatar={
                    <Avatar
                        src={`${
                            isBusiness
                                ? 'https://api.bidwork.com.au/api/files/app_icon_business_512.png'
                                : 'https://api.bidwork.com.au/api/files/app_icon_onthefly_512.png'
                        }`}
                        variant="square"
                        aria-label="App icon"
                        className={styles.app_dl_icon}
                    />
                }
                title={title}
                subheader={subtitle}
            />
            <CardContent>
                {/* Bigger screens. */}
                <Hidden smDown>
                    <GridList cellHeight={48} cols={2}>
                        {AppTiles(styles, key, styles.app_dl_store_badge, app_store_link, play_store_link)}
                    </GridList>
                </Hidden>
                {/* Small screens. */}
                <Hidden mdUp>
                    <GridList cellHeight={60} cols={1}>
                        {AppTiles(styles, key, styles.app_dl_store_badge_same_width, app_store_link, play_store_link)}
                    </GridList>
                </Hidden>
            </CardContent>
        </Card>
    );
}

export default function App() {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const [keywords, setKeywords] = React.useState('');
    const indicator = new URLSearchParams(window.location.search).get('v');
    console.log(indicator);
    let inintTabIndex = 0;
    if (indicator) {
        inintTabIndex = 1;
    }
    const [tabIndex, setTabIndex] = React.useState(inintTabIndex);
    const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
        setTabIndex(newIndex);
    };

    return (
        <div className={styles.root}>
            {/* {createAppBar(styles, anchorEl, setAnchorEl)} */}
            <Routes>
                <Route path="/account_deletion" element={<AccountDeletion />} />
                <Route
                    path="*"
                    element={
                        <div className={styles.container_bg}>
                            <Container maxWidth="md">
                                <Typography variant="h3" className={styles.leading_text}>
                                    I am ...
                                </Typography>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    aria-label="purpose selection"
                                    centered
                                    textColor="secondary"
                                >
                                    <Tab
                                        label="Looking for services"
                                        {...a11yProps(0)}
                                        className={styles.tab_general}
                                    />
                                    <Tab label="Running a business" {...a11yProps(1)} className={styles.tab_general} />
                                </Tabs>
                            </Container>
                            <div className={styles.container_bottom_section}>
                                <Container maxWidth="sm">
                                    {/* Tab panel for users. */}
                                    <TabPanel value={tabIndex} index={0}>
                                        {AppCard(
                                            styles,
                                            'otf',
                                            'BidWork',
                                            'Post your job on the fly',
                                            false,
                                            'https://apps.apple.com/au/app/bidwork-onthefly/id1518052170',
                                            'https://play.google.com/store/apps/details?id=com.bidwork.onthefly'
                                        )}
                                    </TabPanel>

                                    {/* Tab panel for business. */}
                                    <TabPanel value={tabIndex} index={1}>
                                        {AppCard(
                                            styles,
                                            'bis',
                                            'BidWork-Tradie',
                                            'More opportunites for your business',
                                            true,
                                            'https://apps.apple.com/au/app/bidwork-tradie/id1511411314',
                                            'https://play.google.com/store/apps/details?id=com.bidwork.business'
                                        )}
                                    </TabPanel>
                                </Container>
                            </div>
                        </div>
                    }
                />
            </Routes>

            {/* Add more <div> here for other sections like features, etc. */}

            <div className={styles.copyright_container_bg}>
                <Container maxWidth="md">{Copyright(styles)}</Container>
            </div>
        </div>
    );
}
