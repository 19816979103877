import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: '80px',
            minHeight: '80vh',
            // backgroundColor: 'lightblue',
            // color: theme.palette.primary.main
        },
    })
);

function AccountDeletion() {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <h3>Account/Data Deletion</h3>
            <p>
                In order to delete your Bidwork(Bidwork/Bidwork Business) account or data, please send your request to{' '}
                <i>bidworkteam@gmail.com</i>
            </p>
            <p>
                BidWork Team <br /> 2 Aug 2024
            </p>
        </div>
    );
}

export default AccountDeletion;
